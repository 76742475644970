<template>
  <v-container fluid>
    <v-row>
      <!-- Sidebar for folders -->
      <v-col cols="3">
        <v-navigation-drawer permanent width="100%" class="pt-0 no-border" flat>
          <v-list dense>
            <v-list-item>
              <v-list-item-content class="d-flex justify-space-between align-center">
                <v-list-item-title class="title">Folders</v-list-item-title>
                <v-btn @click="showCreateFolderModal = true" color="primary">
                  <v-icon left>add_circle</v-icon> Create New Folder
                </v-btn>
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item-group v-model="selectedFolder" @change="onFolderClick">
              <v-list-item v-for="(folder, index) in folders" :key="index" :value="folder.name">
                <v-list-item-icon>
                  <v-icon>folder</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ folder.name }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-navigation-drawer>
      </v-col>
      <!-- Main content area for files -->
      <v-col cols="9">
        <v-card class="pa-3 no-border" flat>
          <v-toolbar flat>
            <v-toolbar-title>Shared Documents</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click="refreshSharedDocuments">
              <v-icon>refresh</v-icon>
            </v-btn>
          </v-toolbar>
          <v-data-table
            :headers="headers"
            :items="filteredFiles"
            class="elevation-0 no-border"
          >
            <template v-slot:item.filename="{ item }">
              <span
                v-if="selectedFolder === 'Terms and Conditions' && item.filename === 'Loglive Terms and Condition'"
                @click.prevent="showTerms = true"
                class="file-name"
                style="cursor: pointer;"
              >
                {{ item.filename }}
              </span>
              <span
                v-else
                @click="viewSharedFile(item)"
                class="file-name"
                style="cursor: pointer;"
              >
                {{ item.filename }}
              </span>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
              <v-icon small @click="downloadFile(item)"  v-bind="attrs" v-on="on" >download</v-icon>
            </template>
              <span>Download</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
              <v-icon small @click="moveFileToFolder(item)" v-bind="attrs" v-on="on">file_open</v-icon>
            </template>
              <span>Move file</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <!-- Modal for viewing shared file -->
    <v-dialog v-model="showFileModal" max-width="800px">
      <v-card>
        <v-card-title class="d-flex justify-space-between align-center">
          <span>{{ currentFile.filename }}</span>
          <v-btn icon @click="closeFileModal">
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <div v-html="cleanedContent"></div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Modal for creating a new folder -->
    <v-dialog v-model="showCreateFolderModal" max-width="500px">
      <v-card>
        <v-card-title>Create New Folder</v-card-title>
        <v-card-text>
          <v-text-field v-model="newFolderName" label="Folder Name" required></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="createFolder">Create</v-btn>
          <v-btn text @click="showCreateFolderModal = false">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Modal for moving file to folder -->
    <v-dialog v-model="showMoveFileModal" max-width="500px">
      <v-card>
        <v-card-title>Move File to Folder</v-card-title>
        <v-card-text>
          <v-select v-model="selectedFolderToMove" :items="folders" item-text="name" item-value="name" label="Select Folder" required></v-select>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="moveFile">Move</v-btn>
          <v-btn text @click="showMoveFileModal = false">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Modal for Terms and Conditions -->
    <termsandconditions
      :showDialog.sync="showTerms"
      :content="termsContent"
      :version="termsVersion"
      @close="showTerms = false"
      @accept-terms="onTermsAccepted"
    />
  </v-container>
</template>

<script>
import Termsandconditions from '../TermsAndConditions/Termsandconditions.vue';

export default {
  components: {
    Termsandconditions,
  },
  data() {
    return {
      sharedFiles: [],
      folders: [
        { name: 'Shared Documents', icon: 'folder' },
        { name: 'Terms and Conditions', icon: 'folder' }  // Added new folder
      ],
      selectedFolder: 'Shared Documents',  // Default to 'Shared Documents'
      selectedFolderToMove: null,
      fileToMove: null,
      newFolderName: '',
      showCreateFolderModal: false,
      showMoveFileModal: false,
      showFileModal: false,
      showTerms: false,  // For terms modal
      termsContent: '',  // For terms content
      termsVersion: '',  // For terms version
      currentFile: {},
      headers: [
        { text: 'Name', value: 'filename', sortable: true },
        { text: 'Last Modified', value: 'lastModified', sortable: true },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
    };
  },
  computed: {
    filteredFiles() {
      if (this.selectedFolder === 'Terms and Conditions') {
        return [{ filename: 'Loglive Terms and Condition' }];
      } else if (this.selectedFolder) {
        return this.sharedFiles.filter(file => file.folder === this.selectedFolder);
      }
      return this.sharedFiles;
    },
    cleanedContent() {
      if (!this.currentFile.content) return '';

      let content = this.currentFile.content;

      content = content.replace(/<p><\/p>/g, '');
      content = content.replace(/\s{2,}/g, ' ');
      content = content.replace(/<pre><code class="language-vue">/g, '<pre><code class="language-vue" style="white-space: pre-wrap;">');

      return content;
    },
  },
  methods: {
    async fetchSharedDocuments() {
      try {
        const response = await this.$API.fetchSharedDocuments({
          organisationId: this.$store.state.currentOrg.id,
        });
        console.log('Shared Documents Response:', response);
        this.sharedFiles = response.files;
      } catch (error) {
        console.error('Error fetching shared documents:', error);
        this.$toast.error('Error fetching shared documents');
      }
    },
    onFolderClick(folder) {
      this.selectedFolder = folder;
    },
    viewSharedFile(file) {
    this.currentFile = file;
    this.showFileModal = true;
    this.replacePlaceholdersInView(); // Replace placeholders with actual values
  },

  replacePlaceholdersInView() {
    if (this.currentFile && this.currentFile.content) {
      const content = this.currentFile.content;
      const updatedContent = this.replacePlaceholders(content, false);
      this.currentFile.content = updatedContent;
    }
  },

    downloadFile(file) {
      const content = file.content;
      const filename = file.filename;

      if (filename && content) {
        const docContent = `
          <html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'>
          <head><meta charset='utf-8'><title>Export HTML to Word Document</title></head>
          <style>
            body { font-family: Arial, sans-serif; }
            p { margin: 0; padding: 0; }
            pre { font-family: Courier New, monospace; }
            code { font-family: Courier New, monospace; }
          </style>
          </head>
          <body>${content}</body>
          </html>
        `;
        const blob = new Blob(['\ufeff', docContent], { type: 'application/msword' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = `${filename}.doc`;
        link.click();
      } else {
        alert('Unable to download file. Missing content or filename.');
      }
    },
    closeFileModal() {
      this.showFileModal = false;
      this.currentFile = {};
    },
    refreshSharedDocuments() {
      this.fetchSharedDocuments();
    },
    async createFolder() {
      try {
        console.log('Creating folder:', this.newFolderName);
        const response = await this.$API.sharedCreateFolder({
          folderName: this.newFolderName,
          userId: this.$store.state.user.id,
          organisationId: this.$store.state.currentOrg.id,
        });
        console.log('Create Folder Response:', response);
        this.folders.push(response.folder);
        this.showCreateFolderModal = false;
        this.newFolderName = '';
        this.refreshSharedDocuments(); // Refresh documents to show the new folder
      } catch (error) {
        console.error('Error creating folder:', error);
        this.$toast.error('Error creating folder');
      }
    },
    selectFolder(folder) {
      this.selectedFolder = folder;
    },
    moveFileToFolder(file) {
      this.fileToMove = file;
      this.showMoveFileModal = true;
    },
    async moveFile() {
      if (this.fileToMove && this.selectedFolderToMove) {
        try {
          console.log('Moving file:', this.fileToMove, 'to folder:', this.selectedFolderToMove);
          const response = await this.$API.sharedMoveFile({
            filename: this.fileToMove.filename,
            newFolderName: this.selectedFolderToMove,
            userId: this.$store.state.user.id,
            organisationId: this.$store.state.currentOrg.id,
          });
          console.log('Move File Response:', response);
          this.fileToMove.folder = this.selectedFolderToMove;
          this.showMoveFileModal = false;
          this.fileToMove = null;
          this.selectedFolderToMove = null;
          this.refreshSharedDocuments();
        } catch (error) {
          console.error('Error moving file:', error);
          this.$toast.error('Error moving file');
        }
      }
    },
    async fetchLatestTerms() {
      try {
        const response = await this.$API.getTerms({
          organisationId: this.$store.state.currentOrg.id
        })
        const latestContent = response.data.content;
        const latestVersion = response.data.version;

        if (this.termsVersion !== latestVersion) {
          this.termsContent = latestContent;
          this.termsVersion = latestVersion;
        }
      } catch (error) {
        console.error('Error fetching terms:', error);
      }
    },
    onTermsAccepted(version) {
      this.termsVersion = version;
      this.showTerms = false;
    }
  },
  created() {
    this.fetchSharedDocuments();
    this.fetchLatestTerms();
  },
};
</script>
